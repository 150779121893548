







import { defineComponent  } from "@vue/composition-api";
import DefaultLayout from "@/layouts/default.vue"; 
import ContainerProposePage from "@/components/containers/ContainerProposePage.vue";
export default defineComponent({
   components: {
   DefaultLayout,
   ContainerProposePage,
   },
   created(){
     this.$store.commit("SET_BREADCRUMB", {
        viewTxt: "Tôi gửi đi",
        ableToBack: false,
        rootRouter: "/",
        hasStatus: false,
        statusTxt: "",
     });
   },
   methods:{
      
   }
});
