














































































































































































import Vue from 'vue';
import { ref, defineComponent, reactive, watch, toRefs } from '@vue/composition-api';
import { TableFiltersInput, TableFiltersSelect, TableFiltersDateRange } from '@/components/Shared/index';
import { NormalFilterObject } from '@/InterfaceModel/Filter';
import { returnFilterObject } from '@/utils/TableFilters';
export default defineComponent({
	props: {
		tableData: {
			type: Array,
			default: [],
		},
		tableLoading: {
			type: Boolean,
		},
		headers: {
			type: Array,
			default: [],
		},
		handleFilterChange: {
			type: Function,
		},
		currentBindingUrl: {
			type: Object,
		},
		handleSelectedItem: {
			type: Function,
		},
		handleRemoveItem: {
			type: Function,
		},
		handleSelectedItemDetail: {
			type: Function,
		},
	},
	components: { TableFiltersInput, TableFiltersSelect, TableFiltersDateRange },
	setup: (props, ctx) => {
		const endedThead = ref<number>(40);
		const tableHeight = ref<number>(600);
		let filtersTable = ref<Record<string, unknown>>({});
		let selectedData = ref<Record<string, unknown>>({});
		let selectedDataDetail = ref<Record<string, unknown>>({});

		const setEndedThead = (payload: number) => {
			endedThead.value = payload;
		};
		const setSelectedData = (payload: Record<string, unknown>) => {
			selectedData.value = payload;
		};

		const sendItem = (item: Record<string, string>) => {
			setSelectedData(item);
			ctx.emit('onSendItem', selectedData.value);
		};
		const detailItem = (item: Record<string, string>) => {
			setSelectedData(item);
			ctx.emit('handleSelectedItemDetail', selectedData.value);
		};

		const deleteItem = (item: Record<string, string>) => {
			setSelectedData(item);
			ctx.emit('handleRemoveItem', selectedData.value);
		};
		const setTableHeight = (payload: number) => {
			tableHeight.value = payload;
		};
		const setFiltersTable = (payload: Record<string, unknown>) => {
			filtersTable.value = payload;
		};
		watch(filtersTable, currentValue => {
			// reactive when filter change here
			ctx.emit('handleFilterChange', currentValue);
		});
		//  watch(selectedData, currentValue => {
		//   // reactive when filter change here
		//  });
		//  watch(selectedDataDetail, currentValue => {
		//  });
		return {
			filtersTable,
			tableHeight,
			endedThead,
			selectedData,
			selectedDataDetail,
			setEndedThead,
			setTableHeight,
			setFiltersTable,
			setSelectedData,
			detailItem,
			deleteItem,
			sendItem,
		};
	},
	data() {
		return {
			arraySort: [],
		};
	},
	mounted() {
		try {
			if (this.$refs.tableMod) {
				const myTable = (this.$refs.tableMod as Vue).$el as HTMLElement;
				const myThead = myTable.querySelector('thead');
				//  this.endedThead= myThead?.clientHeight
				if (myThead?.clientHeight) {
					this.setEndedThead(myThead.clientHeight);
				}
				this.setTableHeight(window.innerHeight - myTable.offsetTop - 80);
			}
		} catch (error) {
			console.log(error);
		}
	},

	methods: {
		getUserFromTitles(data: Record<string, any>) {
			return data.user ? data.user.full_name : '---';
		},
		formatDate(ds: any) {
			if (!ds) {
				return '';
			}
			let ar1 = ds.split(' ');
			let ar2 = ar1[0].split('-');
			return `${ar2[2]}/${ar2[1]}/${ar2[0]}`;
		},
		viewDetail(data: Record<string, any>) {
			this.$router.push(`/de-xuat/chi-tiet/${data.id}`);
		},
		onUpdate(data: Record<string, any>) {
			this.$router.push(`/de-xuat/chinh-sua/${data.id}`);
		},
		listenChange(value: NormalFilterObject) {
			const valObject = returnFilterObject(value);
			const body = {
				...this.filtersTable,
				...valObject,
			};
			this.setFiltersTable(body);
		},
		listenDateChange(value: NormalFilterObject) {
			const valObject = { ...value };
			const body = {
				...this.filtersTable,
				...valObject,
			};
			this.setFiltersTable(body);
		},
	},
});
